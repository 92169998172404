.storybook-input-base::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9b9b9;
  opacity: 1; /* Firefox */
}

.storybook-input-base:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9b9b9;
}

.storybook-input-base::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9b9b9;
}

.storybook-input--outer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.storybook-input--input-container {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0.5rem 0;
  position: relative;
}

.storybook-input--full-width {
  width: 100%;
}

.storybook-input--label-text {
  font-size: 1rem;
  color: #6e9191;
}

.storybook-input--full-width {
  width: 100%;
}

.storybook-input--footer-label-text {
  font-size: 0.75rem;
  color: #165050;
  line-height: 1rem;
}

.footer-label--error {
  color: #e5554f;
}

.storybook-input--error-icon-container {
  position: relative;
  right: 2.5rem;
}

.storybook-input--error-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.storybook-input-base {
  background-color: #f3f6f6;
  outline: none;
  border: 1px solid #f3f6f6;
  display: flex;
  flex: 1;

  font-size: 1rem;
  padding-left: 1rem;
  color: #165050;
}

.storybook-input--disabled {
  color: #cad5d5;
}

.storybook-input-base--medium {
  height: 48px;
}

.storybook-input-base--large {
  height: 56px;
}

.storybook-input-base:focus {
  background-color: #ffffff;
  border: 1px solid #ade3d7;
  box-shadow: 0 0 0 3px #c0d1d333;
}

.storybook-input-base-outline {
  background-color: white;
}

.storybook-input--error::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ec7f7b;
  opacity: 1; /* Firefox */
}

.storybook-input--error:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ec7f7b;
}

.storybook-input--error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ec7f7b;
}

.storybook-input--error {
  background-color: #fdf2f2;
  color: #73302e;
  border: 1px solid #e5554f;
  box-shadow: 0 0 0 3px #f9d6d5;
  padding-right: 48px;
}

.storybook-input--error:focus {
  background-color: #ffffff;
  border: 1px solid #e5554f;
  box-shadow: 0 0 0 3px #f9d6d5;
}
