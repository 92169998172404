.storybook-avatar {
  object-fit: cover;
  border: 1px solid white;
}

.storybook-avatar--container {
  position: relative;
  display: inline-block;
  align-items: center;
}

.storybook-avatar--dropdown-container {
  flex-direction: row;
  display: inline-flex;
}

.storybook-avatar--dropdown-button {
  all: unset;
  cursor: pointer;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
}

.storybook-avatar--dropdown-button:focus {
  outline: #165050 5px auto;
}

.storybook-avatar--dropdown-icon {
  width: 16px;
  height: 16px;
}

.--inactive {
  animation: rotate 0.2s ease-out reverse;
  transform: scaleY(1);
}

.--active {
  animation: rotate 0.2s ease-out;
  transform: scaleY(-1);
}

@keyframes rotate {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(-1);
  }
}

.storybook-avatar--container-group--medium {
  margin-left: -0.5rem;
}

.storybook-avatar--container-group--small {
  margin-left: -0.4rem;
}

.storybook-avatar--container-group--x-small {
  margin-left: -0.3rem;
}

.storybook-avatar--group-overflow--text {
  font-weight: 400;
  color: #165050;
}

.storybook-avatar--group-overflow--text--medium {
  font-size: 16px;
}

.storybook-avatar--group-overflow--text--small {
  font-size: 14px;
}

.storybook-avatar--group-overflow--text--x-small {
  font-size: 10px;
}

.storybook-avatar--group-overflow--container {
  border: 1px solid white;
  background-color: #ddeeee;
  justify-content: center;
  align-items: center;
  display: flex;
}

.storybook-avatar--placeholder--container {
  border: 1px solid white;
  background-color: #808080;
  justify-content: center;
  align-items: center;
  display: flex;
}

.storybook-avatar--placeholder--image {
  object-fit: cover;
}

.storybook-avatar--placeholder--image--xlarge {
  width: 100px;
  height: 100px;
}

.storybook-avatar--placeholder--image--large {
  width: 48px;
  height: 48px;
}

.storybook-avatar--placeholder--image--medium {
  width: 20px;
  height: 20px;
}

.storybook-avatar--placeholder--image--small {
  width: 16px;
  height: 16px;
}

.storybook-avatar--placeholder--image--x-small {
  width: 12px;
  height: 12px;
}

.storybook-avatar--online-badge {
  background-color: #6dcdb8;
  border: 1px solid white;
  position: absolute;
  top: 0;
  right: 0;
}

.storybook-avatar--online-badge--medium {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.storybook-avatar--online-badge--small {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.storybook-avatar--online-badge--x-small {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

.storybook-avatar--xlarge {
  width: 200px;
  height: 200px;
  border-radius: 100px;
}

.storybook-avatar--large {
  width: 96px;
  height: 96px;
  border-radius: 80px;
}

.storybook-avatar--medium {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.storybook-avatar--small {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.storybook-avatar--x-small {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.storybook-avatar-group--container {
  display: flex;
  flex-direction: row;
}
