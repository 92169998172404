html {
  height: 100%;
  width: 100%;
}

@keyframes fadeIn {
  0% {
    background-color: #f0fbfd;
  }
  100% {
    background-size: cover;
  }
}

@font-face {
  font-family: 'Euclid';
  src: local('Euclid'),
    url('./assets/fonts/EuclidCircularB-Light.otf') format('opentype');
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid';
  src: local('Euclid'),
    url('./assets/fonts/EuclidCircularB-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid';
  src: local('Euclid'),
    url('./assets/fonts/EuclidCircularB-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid';
  src: local('Euclid'),
    url('./assets/fonts/EuclidCircularB-Semibold.otf') format('opentype');
  font-weight: 600;
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid';
  src: local('Euclid'),
    url('./assets/fonts/EuclidCircularB-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'),
    url('./assets/fonts/DINCondensed-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  /* background: #9FAFB; */
  animation: fadeIn 200ms ease-in forwards;
  background-size: cover;
  background-attachment: scroll;
  font-size: 18px;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
