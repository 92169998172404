.storybook-large-input-base::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9b9b9;
  opacity: 1; /* Firefox */
  font-size: 4rem;
  background-color: transparent;
}

.storybook-large-input-base:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9b9b9;
  font-size: 4rem;
  background-color: transparent;
}

.storybook-large-input-base::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9b9b9;
  font-size: 4rem;
  background-color: transparent;
}

.storybook-large-input-base {
  outline: none;
  border: none;
  width: 120px;
  font-size: 4rem;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  color: #165050;
  block-size: fit-content;
  text-align: center;
}

.storybook-input-base--medium {
  height: 48px;
}

.storybook-large-input-base--large {
  height: 56px;
}

.storybook-large-input--outer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.storybook-large-input--input-container {
  align-items: center;
}

.storybook-large-input--label-text {
  font-size: 1.125rem;
  color: #6e9191;
  margin-left: 1rem;
}

.storybook-large-input--base-underline {
  border: 1px solid #ebf0f0;
  width: 100%;
}
