body {
  background-color: #fcfcfc;
}

.storybook-dropdown--container {
  display: flex;
  flex: 1;
  background-color: #f3f6f6;
  max-height: 48px;

  align-items: center;
  display: flex;
  position: relative;
  margin-top: 10px;
}

.storybook-dropdown--text {
  font-size: 1rem;
  color: #a9b9b9;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 16px;
}

.--dropdown-text-empty {
  color: #165050;
}

.storybook-dropdown--button {
  all: unset;
  cursor: pointer;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 1rem;
}

.storybook-dropdown--button:focus {
  outline: #165050 5px auto;
}

.storybook-dropdown--icon {
  width: 24px;
  height: 24px;
}

.--inactive {
  animation: rotate 0.2s ease-out reverse;
  transform: scaleY(1);
}

.--active {
  animation: rotate 0.2s ease-out;
  transform: scaleY(-1);
}

@keyframes rotate {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(-1);
  }
}

.storybook-dropdown--dropdown-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0;

  box-shadow: 0 20px 40px rgba(144, 165, 165, 0.11),
    0 2px 4px rgba(207, 208, 208, 0.25);
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  background-color: white;
  top: 70px;
}

.storybook-dropdown--dropdown-item {
  color: #165050;
  align-items: center;
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  flex: 1;
  text-transform: capitalize;
}

.storybook-dropdown--item-check {
  width: 24px;
  height: 24px;
  float: right;
}

.--dropdown-item-default {
  color: #a9b9b9;
}

.storybook-dropdown--dropdown-item:hover {
  cursor: pointer;
  background-color: #f3f6f6;
}

.--dropdown-inactive {
  animation: grow 200ms ease-out reverse;
}

.--dropdown-active {
  animation: grow 200ms ease-out;
}

.--dropdown-item-inactive {
  display: none;
}

.--dropdown-item-active {
  display: block;
}

@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes fade {
  0% {
    transform: scale(0);
  }
  100% {
    height: scale(1);
  }
}
